<template>
  <b-container class="mt-sm-5">
    <b-row>
      <h2 class="centered">
        <span>{{ $t("what-have-we") }}</span>
        &nbsp;
        <span class="w-color-orange z-fancy-underline">
          {{ $t("achieved") }}!
          <svg-fancy-underline-icon />
        </span>
      </h2>
      <b-col
        sm="12"
        lg="4"
        class="box"
        v-for="item in cardArr"
        :key="item.title"
      >
        <div>
          <img :src="item.img" :alt="item.title" />
          <h3>
            <span :style="item.color">{{ item.colorTitle }}</span> <br />
            {{ item.title }}
          </h3>
          <div class="centered">
            <p>
              {{ item.text }}
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12" class="d-flex align-items-center justify-content-center">
        <b-button
          variant="primary"
          class="a-poppins-btn -wider"
          href="https://zmudri.darujme.sk/podpora-zmudri-sk-17ea0-8eb75/"
          target="_blank"
          rel="noopener noreferrer"
          >{{ $t("Podporte nás") }}</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      cardArr: [
        {
          img: "Prirucka_Politika_polopate.webp",
          colorTitle: this.$t("Naučili sme mladých"),
          title: " " + this.$t("zodpovedne voliť"),
          text: this.$t("about-us-elections"),
          color: "color:#0165ff",
        },
        {
          img: "Rectangle 337.webp",
          colorTitle: this.$t("Chodíme aj priamo k učiteľom"),
          title: " " + this.$t("do regiónov"),
          text: this.$t("about-us-not-just-online"),
          color: "color:#F2994A",
        },
        {
          img: "Rectangle 335.webp",
          colorTitle: this.$t("Podporili sme učiteľov počas"),
          title: " " + this.$t("najnáročnejšieho obdobia"),
          text: this.$t(
            "Okrem klasických video kurzov pre študentov sme natočili aj špeciálny video kurz pre viac ako 2000 registrovaných učiteľov o tvorbe náučného video obsahu pre študentov. Zároveň sme vytvorili komunitu s viac ako 1000 inovatívnymi učiteľmi."
          ),
          color: "color:#FB2564",
        },
      ],
    };
  },
  components: {
    "svg-fancy-underline-icon": () =>
      import("/assets/icons/fancyUnderline.svg?inline"),
  },
};
</script>

<style lang="scss" scoped>
.centered {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 0.5rem;
}
.box {
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  width: 100%;
  h3 {
    min-height: 5rem;
    margin-bottom: 1rem;
  }
  img {
    margin-bottom: 3rem;
    justify-self: center;
    max-width: 100%;
  }
  p,
  a {
    line-height: 28px;
    font-size: 14px;
    font-weight: 100;
  }
}
@media only screen and (max-width: 991px) {
  .box {
    width: 100%;
    text-align: center;
    h3 {
      height: auto;
    }
    p {
      width: 80%;
      height: auto;
    }
  }
}
</style>
